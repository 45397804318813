/* navigation */
.navbar-white {
  background-color: #013565;
  color: #ffffff;
}

a {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0392dd;
  text-decoration: none;
}

.btn-primary {
  color: #fff;
  background-color: #013565;
  border-color: #013565;
  box-shadow: none;
}

.btn-primary:hover {
  color: #fff;
  background-color: #024d91;
  border-color: #024d91;
  box-shadow: none;
}

/* dashboard */
.callout.callout-notification {
  border-left-color: #ff8400;
}

.text-order {
  color: #ff8400!important;
}

/* pagination */
.page-item.active .page-link {
  color: #fff;
  background-color: #024d91;
  border-color: #024d91;
}

.page-link {
  color: #024d91;
}

.custom-loader {
  width:35px;
  height:35px;
  border-radius:50%;
  /* background:conic-gradient(#0000 10%,#766DF4);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
  margin: 0 auto; */
  background:conic-gradient(#0000 10%,#013565);
  -webkit-mask:radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 2s infinite linear;
  margin: 0 auto;
}
@keyframes s3 {to{transform: rotate(1turn)}}
